import React, { useState, useEffect, useRef } from "react";
import img1 from "./img/chalet1.jpg";
import img2 from "./img/chalet2.jpg";
import img3 from "./img/chalet3.jpg";
import img4 from "./img/chalet4.jpg";
import "./App.css";

const Slide = ({ num }) => {
  switch (num) {
    case 1:
      return <img src={img4} alt="outside" />;
    case 2:
      return <img src={img2} alt="kitchen" />;
    case 3:
      return <img src={img3} alt="fireplace" />;
    case 4:
      return <img src={img1} alt="bedroom" />;
    default:
      return null;
  }
};

const Gallery = () => {
  const [slide, setSlide] = useState(1);
  const timeRef1 = useRef();
  const timeRef2 = useRef();

  const autoPlay = () =>
    (timeRef1.current = setInterval(() => setSlide((s) => s + 1), 5000));

  const clicc = (dir) => {
    setSlide(dir);
    clearInterval(timeRef1.current);
    clearTimeout(timeRef2.current);
    timeRef2.current = setTimeout(autoPlay, 15000);
  };

  useEffect(() => {
    autoPlay();
    return () => clearInterval(timeRef1.current);
  }, []);

  useEffect(() => {
    if (slide > 4) {
      setSlide(1);
    } else if (slide < 1) {
      setSlide(4);
    }
  }, [slide]);

  return (
    <div className="gallery">
      <button className="leftBtn" onClick={() => clicc((s) => s - 1)}>
        {"<"}
      </button>
      <button className="rightBtn" onClick={() => clicc((s) => s + 1)}>
        {">"}
      </button>
      <Slide num={slide} />
    </div>
  );
};

const App = () => {
  const [pop, setPop] = useState(false);

  return (
    <>
      <div className={pop ? "popup-back" : "popup-back hide"}>
        <div className="popup">
          <h3>Contact Us</h3>
          <p>
            You can email us at{" "}
            <a href="mailto:samuelnewman@protonmail.com">
              samuelnewman@protonmail.com
            </a>
          </p>
          <button className="contactBtn" onClick={() => setPop(false)}>
            Back
          </button>
        </div>
      </div>
      <div className="container">
        <div className="left" />
        <div className="right">
          <h1>Refuge de la Combe</h1>
          <p>
            <span>
              Located in the French Alps just below the ski resort Les 3
              Vallées, Refuge de la Combe is a traditional alpine chalet in a
              quiet hamlet.
            </span>
          </p>
          <p>
            The nearby town, Saint-Martin-de-Belleville not only contains shops,
            bars and restaurants, but also your gateway to the underappreciated
            valley of St Martin, recently improved with a new, fast chairlift
            and with a wide range of slopes (and off-piste) that can cater to
            all ability levels. A busy place, from which the Chalet provides a
            refuge.
          </p>
          <p>
            The chalet itself is a renovated barn, with a spacious living area,
            kitchen, and three bedrooms, each with a bathroom. The master
            bedroom, at the top, provides beautiful views of the valley below.
            There is also a boot room to store skis and boots, and a terrace.
          </p>
          <button className="contactBtn" onClick={() => setPop(true)}>
            Contact
          </button>
          <Gallery />
          <iframe
            title="Chalet Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d339.4073466392001!2d6.48939744303535!3d45.41314024827303!2m3!1f226.26562500000148!2f46.185068672966615!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x478a2b31ddef3765%3A0x6f8d7fce0bf65cde!2sLa%20Combe%2C%2073440%20Saint-Jean-de-Belleville%2C%20France!5e1!3m2!1sen!2suk!4v1592583699054!5m2!1sen!2suk"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex="0"
          />
        </div>
      </div>
    </>
  );
};

export default App;
